import { FC, lazy } from "react"
import { Outlet, RouteObject } from "react-router-dom"

import DefaultLayout, { Loader } from "../components/layout"

const Home = lazy<FC>(() => import("./home"))
const DynamicPage = lazy<FC>(() => import("./dynamic"))
const NotFound = lazy<FC>(() => import("./notFound"))
const TermsAndPrivacy = lazy<FC>(() => import("./termsAndPrivacy"))
const ResourceArticlePage = lazy<FC>(() => import("./resourceArticle"))

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
      },
      {
        Component: DynamicPage,
        path: "/:slug",
        loader: () => <Loader />,
      },
      {
        Component: ResourceArticlePage,
        path: "/resources/:slug",
        loader: () => <Loader />,
      },
      {
        Component: TermsAndPrivacy,
        path: "/terms-privacy",
        loader: () => <Loader />,
      },
    ],
  },
]

export default routes
