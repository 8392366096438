import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentSectionsChooseUsSection",
      "ComponentSectionsFrameworksSection",
      "ComponentSectionsHeroSection",
      "ComponentSectionsPartnersSection",
      "ComponentUiButton",
      "ComponentUiCard",
      "ComponentUiCaseSection",
      "ComponentUiGrid",
      "ComponentUiGroupLink",
      "ComponentUiHeadline",
      "ComponentUiInput",
      "ComponentUiLink",
      "ComponentUiMonitoringApproachSection",
      "ComponentUiMonitoringCollapseSection",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSolutionsSection",
      "ComponentUiTab",
      "ComponentUiText",
      "Footer",
      "Form",
      "FormContent",
      "HeaderMenu",
      "Home",
      "Layout",
      "NotFound",
      "Page",
      "ReactIconsIconlibrary",
      "Resource",
      "SolutionsMonitoring",
      "Tag",
      "TermsAndPrivacy",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ],
    "PageContentDynamicZone": [
      "ComponentSectionsHeroSection",
      "ComponentUiMonitoringApproachSection",
      "ComponentUiParagraph",
      "Error"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentSectionsChooseUsSection",
      "ComponentSectionsFrameworksSection",
      "ComponentSectionsHeroSection",
      "ComponentSectionsPartnersSection",
      "ComponentUiButton",
      "ComponentUiCard",
      "ComponentUiCaseSection",
      "ComponentUiGrid",
      "ComponentUiGroupLink",
      "ComponentUiHeadline",
      "ComponentUiInput",
      "ComponentUiLink",
      "ComponentUiMonitoringApproachSection",
      "ComponentUiMonitoringCollapseSection",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSolutionsSection",
      "ComponentUiTab",
      "ComponentUiText",
      "Footer",
      "Form",
      "FormContent",
      "HeaderMenu",
      "Home",
      "Layout",
      "NotFound",
      "Page",
      "ReactIconsIconlibrary",
      "Resource",
      "SolutionsMonitoring",
      "Tag",
      "TermsAndPrivacy",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ],
    "PageContentDynamicZone": [
      "ComponentSectionsHeroSection",
      "ComponentUiMonitoringApproachSection",
      "ComponentUiParagraph",
      "Error"
    ]
  }
};
      export default result;
    
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  attributes {
    alternativeText
    url
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  url
  media {
    data {
      ...File
    }
  }
}
    `;
export const CaseSectionFragmentDoc = gql`
    fragment CaseSection on ComponentUiCaseSection {
  title {
    ...Headline
  }
  card {
    ...Card
  }
}
    `;
export const ChooseUsSectionFragmentDoc = gql`
    fragment ChooseUsSection on ComponentSectionsChooseUsSection {
  title
  card {
    ...Card
  }
}
    `;
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const FrameworksSectionFragmentDoc = gql`
    fragment FrameworksSection on ComponentSectionsFrameworksSection {
  title
  description
  buttonName
  img {
    data {
      ...File
    }
  }
}
    `;
export const GroupLinkFragmentDoc = gql`
    fragment GroupLink on ComponentUiGroupLink {
  title
  link {
    icon {
      data {
        ...File
      }
    }
    url
    title
  }
}
    `;
export const InputFragmentDoc = gql`
    fragment Input on ComponentUiInput {
  label
  name
  placeholder
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  icon {
    data {
      ...File
    }
  }
}
    `;
export const HeroSectionFragmentDoc = gql`
    fragment HeroSection on ComponentSectionsHeroSection {
  id
  __typename
  description
  sectionTitle
  buttonName
}
    `;
export const CardSectionFragmentDoc = gql`
    fragment CardSection on ComponentUiMonitoringApproachSection {
  id
  __typename
  title {
    ...Headline
  }
  card {
    ...Card
  }
  img {
    data {
      ...File
    }
  }
  type
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  __typename
  id
  value
}
    `;
export const PageFragmentDoc = gql`
    fragment Page on Page {
  __typename
  slug
  content {
    ...HeroSection
    ...CardSection
    ...Paragraph
  }
  pageTitle
}
    `;
export const PartnersSectionFragmentDoc = gql`
    fragment PartnersSection on ComponentSectionsPartnersSection {
  title
  logos {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  heading {
    ...Card
  }
}
    `;
export const SolutionsSectionFragmentDoc = gql`
    fragment SolutionsSection on ComponentUiSolutionsSection {
  title {
    ...Headline
  }
  card {
    ...Card
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const CreateFormDocument = gql`
    mutation createForm($data: FormInput!) {
  createForm(data: $data) {
    data {
      attributes {
        firstName
        lastName
        email
        country
        organization
        jobTitle
      }
    }
  }
}
    `;
export type CreateFormMutationFn = Apollo.MutationFunction<CreateFormMutation, CreateFormMutationVariables>;
export function useCreateFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormMutation, CreateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormMutation, CreateFormMutationVariables>(CreateFormDocument, options);
      }
export type CreateFormMutationHookResult = ReturnType<typeof useCreateFormMutation>;
export type CreateFormMutationResult = Apollo.MutationResult<CreateFormMutation>;
export const GetCaseStudiesDocument = gql`
    query getCaseStudies($tag: ID) {
  tag(id: $tag) {
    data {
      attributes {
        resources {
          data {
            attributes {
              title
              description
              img {
                data {
                  ...File
                }
              }
              slug
            }
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useGetCaseStudiesQuery(baseOptions?: Apollo.QueryHookOptions<GetCaseStudiesQuery, GetCaseStudiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCaseStudiesQuery, GetCaseStudiesQueryVariables>(GetCaseStudiesDocument, options);
      }
export function useGetCaseStudiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCaseStudiesQuery, GetCaseStudiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCaseStudiesQuery, GetCaseStudiesQueryVariables>(GetCaseStudiesDocument, options);
        }
export type GetCaseStudiesQueryHookResult = ReturnType<typeof useGetCaseStudiesQuery>;
export type GetCaseStudiesLazyQueryHookResult = ReturnType<typeof useGetCaseStudiesLazyQuery>;
export type GetCaseStudiesQueryResult = Apollo.QueryResult<GetCaseStudiesQuery, GetCaseStudiesQueryVariables>;
export const GetFooterDocument = gql`
    query getFooter {
  footer {
    data {
      attributes {
        logo {
          data {
            ...File
          }
        }
        groupLink {
          ...GroupLink
        }
        termsPrivacyLink {
          url
          title
        }
        company
        description
        awards {
          data {
            ...File
          }
        }
      }
    }
  }
}
    ${FileFragmentDoc}
${GroupLinkFragmentDoc}`;
export function useGetFooterQuery(baseOptions?: Apollo.QueryHookOptions<GetFooterQuery, GetFooterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFooterQuery, GetFooterQueryVariables>(GetFooterDocument, options);
      }
export function useGetFooterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFooterQuery, GetFooterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFooterQuery, GetFooterQueryVariables>(GetFooterDocument, options);
        }
export type GetFooterQueryHookResult = ReturnType<typeof useGetFooterQuery>;
export type GetFooterLazyQueryHookResult = ReturnType<typeof useGetFooterLazyQuery>;
export type GetFooterQueryResult = Apollo.QueryResult<GetFooterQuery, GetFooterQueryVariables>;
export const GetFormContentDocument = gql`
    query getFormContent {
  formContent {
    data {
      attributes {
        title
        description
        subtitle
        logo {
          data {
            ...File
          }
        }
        input {
          ...Input
        }
        confirmationText
        buttonText
      }
    }
  }
}
    ${FileFragmentDoc}
${InputFragmentDoc}`;
export function useGetFormContentQuery(baseOptions?: Apollo.QueryHookOptions<GetFormContentQuery, GetFormContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormContentQuery, GetFormContentQueryVariables>(GetFormContentDocument, options);
      }
export function useGetFormContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormContentQuery, GetFormContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormContentQuery, GetFormContentQueryVariables>(GetFormContentDocument, options);
        }
export type GetFormContentQueryHookResult = ReturnType<typeof useGetFormContentQuery>;
export type GetFormContentLazyQueryHookResult = ReturnType<typeof useGetFormContentLazyQuery>;
export type GetFormContentQueryResult = Apollo.QueryResult<GetFormContentQuery, GetFormContentQueryVariables>;
export const GetHeaderMenuDocument = gql`
    query getHeaderMenu {
  headerMenu {
    data {
      attributes {
        buttonName
        dropdownLinks {
          ...Link
        }
        dropdownTitle
        logoGreen {
          data {
            attributes {
              alternativeText
              url
            }
          }
        }
        logoWhite {
          data {
            attributes {
              alternativeText
              url
            }
          }
        }
        mainLinks {
          ...Link
        }
        menuIcon {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    ${LinkFragmentDoc}
${FileFragmentDoc}`;
export function useGetHeaderMenuQuery(baseOptions?: Apollo.QueryHookOptions<GetHeaderMenuQuery, GetHeaderMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHeaderMenuQuery, GetHeaderMenuQueryVariables>(GetHeaderMenuDocument, options);
      }
export function useGetHeaderMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHeaderMenuQuery, GetHeaderMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHeaderMenuQuery, GetHeaderMenuQueryVariables>(GetHeaderMenuDocument, options);
        }
export type GetHeaderMenuQueryHookResult = ReturnType<typeof useGetHeaderMenuQuery>;
export type GetHeaderMenuLazyQueryHookResult = ReturnType<typeof useGetHeaderMenuLazyQuery>;
export type GetHeaderMenuQueryResult = Apollo.QueryResult<GetHeaderMenuQuery, GetHeaderMenuQueryVariables>;
export const GetNotFoundDocument = gql`
    query getNotFound {
  notFound {
    data {
      attributes {
        img {
          data {
            ...File
          }
        }
        title
        description
        button {
          title
          url
        }
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useGetNotFoundQuery(baseOptions?: Apollo.QueryHookOptions<GetNotFoundQuery, GetNotFoundQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotFoundQuery, GetNotFoundQueryVariables>(GetNotFoundDocument, options);
      }
export function useGetNotFoundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotFoundQuery, GetNotFoundQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotFoundQuery, GetNotFoundQueryVariables>(GetNotFoundDocument, options);
        }
export type GetNotFoundQueryHookResult = ReturnType<typeof useGetNotFoundQuery>;
export type GetNotFoundLazyQueryHookResult = ReturnType<typeof useGetNotFoundLazyQuery>;
export type GetNotFoundQueryResult = Apollo.QueryResult<GetNotFoundQuery, GetNotFoundQueryVariables>;
export const GetPagesDocument = gql`
    query getPages($key: StringFilterInput) {
  pages(filters: {slug: $key}) {
    data {
      attributes {
        ...Page
      }
    }
  }
}
    ${PageFragmentDoc}
${HeroSectionFragmentDoc}
${CardSectionFragmentDoc}
${HeadlineFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}
${ParagraphFragmentDoc}`;
export function useGetPagesQuery(baseOptions?: Apollo.QueryHookOptions<GetPagesQuery, GetPagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPagesQuery, GetPagesQueryVariables>(GetPagesDocument, options);
      }
export function useGetPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPagesQuery, GetPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPagesQuery, GetPagesQueryVariables>(GetPagesDocument, options);
        }
export type GetPagesQueryHookResult = ReturnType<typeof useGetPagesQuery>;
export type GetPagesLazyQueryHookResult = ReturnType<typeof useGetPagesLazyQuery>;
export type GetPagesQueryResult = Apollo.QueryResult<GetPagesQuery, GetPagesQueryVariables>;
export const GetResourcesDocument = gql`
    query getResources($key: StringFilterInput) {
  resources(filters: {slug: $key}) {
    __typename
    data {
      attributes {
        __typename
        title
        slug
        description
        img {
          data {
            ...File
          }
        }
        tag {
          data {
            attributes {
              tag
            }
          }
        }
        createdAt
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useGetResourcesQuery(baseOptions?: Apollo.QueryHookOptions<GetResourcesQuery, GetResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourcesQuery, GetResourcesQueryVariables>(GetResourcesDocument, options);
      }
export function useGetResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourcesQuery, GetResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourcesQuery, GetResourcesQueryVariables>(GetResourcesDocument, options);
        }
export type GetResourcesQueryHookResult = ReturnType<typeof useGetResourcesQuery>;
export type GetResourcesLazyQueryHookResult = ReturnType<typeof useGetResourcesLazyQuery>;
export type GetResourcesQueryResult = Apollo.QueryResult<GetResourcesQuery, GetResourcesQueryVariables>;
export const GetTermsAndPrivacyDocument = gql`
    query getTermsAndPrivacy {
  termsAndPrivacy {
    data {
      attributes {
        content
      }
    }
  }
}
    `;
export function useGetTermsAndPrivacyQuery(baseOptions?: Apollo.QueryHookOptions<GetTermsAndPrivacyQuery, GetTermsAndPrivacyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTermsAndPrivacyQuery, GetTermsAndPrivacyQueryVariables>(GetTermsAndPrivacyDocument, options);
      }
export function useGetTermsAndPrivacyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTermsAndPrivacyQuery, GetTermsAndPrivacyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTermsAndPrivacyQuery, GetTermsAndPrivacyQueryVariables>(GetTermsAndPrivacyDocument, options);
        }
export type GetTermsAndPrivacyQueryHookResult = ReturnType<typeof useGetTermsAndPrivacyQuery>;
export type GetTermsAndPrivacyLazyQueryHookResult = ReturnType<typeof useGetTermsAndPrivacyLazyQuery>;
export type GetTermsAndPrivacyQueryResult = Apollo.QueryResult<GetTermsAndPrivacyQuery, GetTermsAndPrivacyQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        heroSection {
          ...HeroSection
        }
        heroImg {
          data {
            ...File
          }
        }
        partnersSection {
          ...PartnersSection
        }
        solutionsSection {
          ...SolutionsSection
        }
        chooseUsSection {
          ...ChooseUsSection
        }
        caseSection {
          ...CaseSection
        }
        frameworksSection {
          ...FrameworksSection
        }
        pageTitle
      }
    }
  }
}
    ${HeroSectionFragmentDoc}
${FileFragmentDoc}
${PartnersSectionFragmentDoc}
${SolutionsSectionFragmentDoc}
${HeadlineFragmentDoc}
${CardFragmentDoc}
${ChooseUsSectionFragmentDoc}
${CaseSectionFragmentDoc}
${FrameworksSectionFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
  }
}
    `;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SolutionsMonitoringDocument = gql`
    query solutionsMonitoring {
  solutionsMonitoring {
    data {
      attributes {
        heroSection {
          ...HeroSection
        }
        collapseSection {
          img {
            data {
              ...File
            }
          }
          collapse {
            ...HeroSection
          }
        }
        approachSection {
          title {
            ...Headline
          }
          card {
            ...Card
          }
        }
        applicationSection {
          title {
            ...Headline
          }
          card {
            ...Card
          }
        }
        methodologySection {
          title {
            ...Headline
          }
          card {
            ...Card
          }
        }
      }
    }
  }
}
    ${HeroSectionFragmentDoc}
${FileFragmentDoc}
${HeadlineFragmentDoc}
${CardFragmentDoc}`;
export function useSolutionsMonitoringQuery(baseOptions?: Apollo.QueryHookOptions<SolutionsMonitoringQuery, SolutionsMonitoringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SolutionsMonitoringQuery, SolutionsMonitoringQueryVariables>(SolutionsMonitoringDocument, options);
      }
export function useSolutionsMonitoringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SolutionsMonitoringQuery, SolutionsMonitoringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SolutionsMonitoringQuery, SolutionsMonitoringQueryVariables>(SolutionsMonitoringDocument, options);
        }
export type SolutionsMonitoringQueryHookResult = ReturnType<typeof useSolutionsMonitoringQuery>;
export type SolutionsMonitoringLazyQueryHookResult = ReturnType<typeof useSolutionsMonitoringLazyQuery>;
export type SolutionsMonitoringQueryResult = Apollo.QueryResult<SolutionsMonitoringQuery, SolutionsMonitoringQueryVariables>;