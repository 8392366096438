import { Layout as BaseLayout } from "antd"
import { FC, Suspense, useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { useModalStore } from "src/store/modalStore"
import { Footer, Header } from "."
import { HeaderMenu } from "../menu"
import { Modal } from "../ui/modal"

const Layout: FC = () => {
  const { isModalOpen, closeModal } = useModalStore()
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [location])

  return (
    <BaseLayout
      style={
        location.pathname === "/" || location.pathname === "/mrv"
          ? { background: `linear-gradient(137.89deg, #051F2E 28.68%, #023F4D 86.43%)` }
          : {}
      }
    >
      <Modal onCancel={closeModal} open={isModalOpen} />
      <BaseLayout.Header>
        <Header renderMenu={HeaderMenu} />
      </BaseLayout.Header>
      <BaseLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </BaseLayout>
      <BaseLayout.Footer>
        <Footer />
      </BaseLayout.Footer>
    </BaseLayout>
  )
}

export { Layout }
