import { Flex,Typography } from "antd"
import React,{ FC } from "react"
import { NavLink } from "react-router-dom"
import { useGetFooterQuery } from "../../graphql"
import { useBreakpoints } from "../../hooks/useBreakpoints"
import { useApp } from "../app"

const {Text} = Typography

const Footer: FC = () => {
  const {isTabletMD, isMobile} = useBreakpoints();
  const { app } = useApp()
  const {data} = useGetFooterQuery();
  const groupLinks = data?.footer?.data?.attributes?.groupLink
  const termsPrivacyLink = data?.footer?.data?.attributes?.termsPrivacyLink
  const footerLogo = data?.footer?.data?.attributes?.logo?.data?.attributes?.url
  const company = data?.footer?.data?.attributes?.company
  const description = data?.footer?.data?.attributes?.description
  const awards = data?.footer?.data?.attributes?.awards?.data

  return (
    <Flex vertical className={"footer-container"} gap={90}>
      {!isTabletMD ?
        <React.Fragment>
          <Flex justify={"space-between"} align={"flex-start"}>
            <Flex gap={15} vertical>
              <NavLink to={'/'}>
                <Flex>
                  <img src={footerLogo} alt={"logo"} />
                </Flex>
              </NavLink>
              {description && <Text className={'footer-description'}>{description}</Text>}
              <Flex gap={30}>
                {!!awards?.length && awards.map((award) => <img style={{maxWidth: '150px', maxHeight: '48px'}} src={award.attributes?.url} alt={""} />)}
              </Flex>
            </Flex>
            {groupLinks && groupLinks.map((group, index) =>
              <Flex key={index} vertical gap={10}>
                <Typography.Title level={4}>{group?.title}</Typography.Title>
                {group?.link && group.link.map((link, index) => <NavLink key={index} to={link?.url ?? '/'}>{link?.title ? link?.title : <img src={link?.icon?.data?.attributes?.url} alt={""} />}</NavLink>)}
              </Flex>
            )}
          </Flex>
        </React.Fragment>
        :
        <React.Fragment>
          <Flex gap={32} vertical align={"flex-start"}>
            <Flex gap={15} vertical>
              <NavLink to={'/'}>
                <Flex>
                  <img src={footerLogo} alt={"logo"} />
                </Flex>
              </NavLink>
              {description && <Text>{description}</Text>}
              <Flex gap={30}>
                {!!awards?.length && awards.map((award) => <img style={{maxWidth: '150px', maxHeight: '48px'}} src={award.attributes?.url} alt={""} />)}
              </Flex>
            </Flex>
            <Flex style={{width: '100%'}} justify={"space-between"}>
              {groupLinks && groupLinks.map((group, index) =>
                <Flex key={index} vertical gap={15}>
                  <Typography.Title level={4}>{group?.title}</Typography.Title>
                  {group?.link && group.link.map((link, index) => <NavLink key={index} to={link?.url ?? '/'}>{link?.title ? link?.title : <img src={link?.icon?.data?.attributes?.url} alt={""} />}</NavLink>)}
                </Flex>
              )}
            </Flex>
          </Flex>
        </React.Fragment> }
      <Flex vertical={isMobile} gap={15} justify={"space-between"} className={"terms-container"}>
        <Typography.Text>{company}</Typography.Text>
        <Flex gap={10}>
            <NavLink to={termsPrivacyLink?.url ?? '/'}>{termsPrivacyLink?.title}</NavLink>
        </Flex>
      </Flex>
    </Flex>
  )
}

export { Footer }
