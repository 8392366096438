import { Flex,Space } from "antd"
import { FC } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { useGetHeaderMenuQuery } from "../../graphql"

interface LogoProps {
  useGreenLogo?: boolean
}

const Logo: FC<LogoProps> = () => {
  const location = useLocation()
  const { data } = useGetHeaderMenuQuery()
  const logoGreen = data?.headerMenu?.data?.attributes?.logoGreen?.data?.attributes?.url
  const logoWhite = data?.headerMenu?.data?.attributes?.logoWhite?.data?.attributes?.url
  const onLogoClick = () => window.location.assign("/")

  return (
    <NavLink to={'/'}>
      <Flex align={"center"}>
        <img src={location.pathname === "/" || location.pathname === "/mrv" ? logoWhite : logoGreen} alt={"logo"} />
      </Flex>
    </NavLink>
  )
}

export { Logo }
