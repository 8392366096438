import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Menu: {
      lineType: undefined,
      lineWidth: undefined,
      horizontalItemSelectedColor: "#05B9A5",
    },
    Layout: {
      colorBgLayout: "unset",
      headerBg: "unset",
      bodyBg: "unset",
    },
    Button: {
      borderRadius: 0,
      defaultBg: "#05B9A5",
      defaultBorderColor: "#05B9A5",
      colorTextDisabled: "#4F5E68",
      defaultColor: "#FFFFFF",
      defaultHoverColor: "#FFFFFF",
      defaultHoverBg: "#0DD3BD",
      defaultHoverBorderColor: "#0DD3BD",
      controlHeight: 52,
      colorLink: "black",
    },
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0,
      fontSizeHeading1: 48,
      fontSizeHeading2: 46,
      fontSizeHeading3: 40,
      fontSizeHeading4: 24,
    },
    Dropdown: {
      borderRadius: 0,
      borderRadiusOuter: 0,
      borderRadiusLG: 0,
      borderRadiusSM: 0,
      borderRadiusXS: 0,
    },
    Input: {
      colorTextPlaceholder: "#848586",
      colorText: "#848586",
    },
    Select: {
      colorTextPlaceholder: "#848586",
      colorText: "#848586",
    },
    Checkbox: {
      colorPrimary: "#05B9A5",
      colorPrimaryHover: "#05B9A5",
      borderRadiusSM: 0,
    },
  },
  token: {
    fontFamily: "Poppins-Regular",
  },
}

export default theme
