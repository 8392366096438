import { Button, Col, Flex, Row } from "antd"
import { FC, useState } from "react"
import { useLocation } from "react-router-dom"
import { useBreakpoints } from "../../hooks/useBreakpoints"
import { useModalStore } from "../../store/modalStore"
import { Logo } from "../logo"
import { HeaderMenu } from "../menu"
import "./index.less"

const Header: FC<{ renderMenu?: FC }> = ({ renderMenu: HorizontalMenu = HeaderMenu }) => {
  const { openModal } = useModalStore()
  const { isTabletMD } = useBreakpoints()
  const location = useLocation()
  return (
    <Flex align={"center"} justify={"space-between"} className={"header-container"}>
      <Col>
        <Logo />
      </Col>
      <Col
        className={
          !isTabletMD
            ? location.pathname === "/" || location.pathname === "/mrv"
              ? "header-menu-light"
              : "header-menu-dark"
            : "burger-menu-icon"
        }
      >
        <HorizontalMenu />
      </Col>
      {!isTabletMD && (
        <Col className={"header-btn"}>
          <Button onClick={openModal}>Get in touch</Button>
        </Col>
      )}
    </Flex>
  )
}

export { Header }
