import { CloseOutlined } from "@ant-design/icons";
import { Modal as AntModal,Button,Checkbox,Flex,Form,Input,ModalProps,Select,Typography } from "antd";
import countries from "i18n-iso-countries";
import enLang from 'i18n-iso-countries/langs/en.json';
import React,{ FC,PropsWithChildren,useState } from "react";
import { useCreateFormMutation,useGetFormContentQuery } from "../../../graphql";
import { useModalStore } from "../../../store/modalStore"
import './index.less';
import { useBreakpoints } from "../../../hooks/useBreakpoints"
const {Title, Text} = Typography
const {Item} = Form

countries.registerLocale(enLang);

const countryOptions = Object.entries(countries.getNames("en", { select: "official" })).map(([code, name]) => ({
  value: name,
  label: name,
}));


interface CustomModalProps extends ModalProps {
  customClassName?: string
}

export const Modal: FC<PropsWithChildren<CustomModalProps>> = ({
  customClassName,
  ...props
}) => {
  const { isTabletMD} = useBreakpoints();
  const [form] = Form.useForm();
  const {closeModal} = useModalStore();
  const content = useGetFormContentQuery().data?.formContent?.data?.attributes
  const [createForm] = useCreateFormMutation();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const defaultProps = {
    styles: {
      mask: { background: `linear-gradient(137.89deg, #051F2E 28.68%, #023F4D 86.43%)` },
    },
    width: "100%",
    footer: null,
    maskClosable: false,
    closeIcon: <CloseOutlined />,
    className: "full-modal-wrapper",
    forceRender: true,
    afterClose: () => {
      form.resetFields
      setIsCheckboxChecked(false)
    },
  }

  const success = (onCloseModal: () => void) => {
    AntModal.success({
      content: (
        <Flex gap={10} align={isTabletMD ? 'flex-start' : "center"} justify={'center'} vertical>
          <Title>Thank you!</Title>
          <Text style={{textAlign: 'center'}}>Thank you for submitting your form. We will respond to you promptly.</Text>
          <Button className={'modal-btn'} onClick={() => {
            onCloseModal();
            AntModal.destroyAll();
          }}>Close</Button>
        </Flex>
      ),
      closable: !isTabletMD,
      icon: false,
      centered: true,
      footer: null,
      className: isTabletMD ? 'mobile-result-modal' : 'result-modal',
      width: isTabletMD ? '100%' : '512px',

    });
  };

  const error = () => {
    AntModal.success({
      content: (
        <Flex gap={10} align={isTabletMD ? 'flex-start' : "center"} justify={'center'} vertical>
          <Title>Opps!</Title>
          <Text style={{textAlign: 'center'}}>Something went wrong. Please, try again later.</Text>
          <Button className={'modal-btn'} onClick={AntModal.destroyAll}>Try again</Button>
        </Flex>
      ),
      closable: !isTabletMD,
      icon: false,
      centered: true,
      footer: null,
      className: isTabletMD ? 'mobile-result-modal' : 'result-modal',
      width: isTabletMD ? '100%' : '512px',

    });
  };

  const onCheckboxChange = (e: { target: { checked: boolean } }) => {
    setIsCheckboxChecked(e.target.checked);
  };


  const onFinish = async (values: FormInput) => {
    try {
      await form.validateFields();
      const response = await createForm({
        variables: {
          data: {
            ...values,
          },
        },
      })
      if (response && response.data) {
        success(closeModal);
        form.resetFields()
      }
    } catch (err) {
      error()
      console.log(err)
    }
  }

  return (
    <AntModal {...defaultProps} {...props}>
      <Flex vertical className={'modal-content'} gap={30}>
        <Title className={'modal-title'} level={3}>{content?.title}</Title>
        <Text className={'modal-description'}>{content?.description}</Text>
        <Form onFinish={onFinish} className={'modal-form'} layout={'vertical'} form={form}>
          <Flex justify={"space-between"} className={'input-container'} wrap={"wrap"} gap={10}>
            {!!content?.input?.length && content.input.map((item) => {
              const applyValidation = !(item?.name === 'organization' || item?.name === 'jobTitle');
              return (
                <Item
                  key={item?.name}
                  className={'input-item'}
                  label={item?.label}
                  name={item?.name ? item?.name : ''}
                  rules={applyValidation ? [{required: true, message: 'This field is required'}] : undefined}
                >
                  {item?.name === "country" ? <Select options={countryOptions} variant={"borderless"} placeholder={item.placeholder}/> : <Input variant={"borderless"} placeholder={item?.placeholder ? item?.placeholder : ''}/>}
                </Item>
              );
            })}
          </Flex>
          <Flex className={'btn-container'} vertical gap={25}>
            <Checkbox checked={isCheckboxChecked} className={'checkbox'} onChange={onCheckboxChange}>{content?.confirmationText}</Checkbox>
            <Button disabled={!isCheckboxChecked} htmlType={'submit'} className={'btn'}>{content?.buttonText}</Button>
          </Flex>
        </Form>
      </Flex>
    </AntModal>
  )
}
