import { CloseOutlined,DownOutlined,MenuOutlined } from '@ant-design/icons';
import { Collapse,Drawer,Dropdown,Flex,Menu,Space,type MenuProps } from "antd";
import React,{ FC,useState } from 'react';
import { NavLink,useLocation } from "react-router-dom";
import { useGetHeaderMenuQuery } from "../../graphql";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import { Logo } from "../logo";
import Navigation from "./Navigation";


const HeaderMenu: FC = () => {
  const {isTabletMD} = useBreakpoints();
  const [open, setOpen] = useState(false)
  const { data } = useGetHeaderMenuQuery();
  const props = data?.headerMenu?.data?.attributes
  const dropdownTitle = props?.dropdownTitle
  const dropdownItems = props?.dropdownLinks
  const menuLinks = props?.mainLinks
  const menuIcon = props?.menuIcon?.data?.attributes?.url
  const { pathname } = useLocation()

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const activeNow = dropdownItems?.some(item => item?.url === pathname);

  const items: MenuProps['items'] = dropdownItems?.map((link, index) => ({
    key: link?.id ?? 'default-key',
    label: isTabletMD ? (
      <Flex gap={20}>
        <NavLink to={link?.url ?? "/"}>{link?.title}</NavLink>
        <small className={"small-sub"}>{pathname === link?.url ? "- Active now" : ""}</small>
      </Flex>) : (
      <NavLink style={{ color: "black" }} to={link?.url ?? "/"}>
        <Flex vertical>
          <span style={{ marginRight: 8, fontSize: "12px", opacity: '50%'}}>{`0${index + 1}`}</span>
          <Flex>{link?.title}</Flex>
        </Flex>
      </NavLink>) ,
  })) ?? [];



  const mainLinks: MenuProps['items'] = menuLinks?.map((link, index) => ({
    key: link?.id ?? 'default-key',
    label: (
     <Flex vertical>
       <small className={"small-sub"}>{`0${index + 3}`}{pathname === link?.url ? ' - Active now' : ''}</small>
       <NavLink to={link?.url ?? "/"}>{link?.title}</NavLink>
     </Flex>
    ),
  })) ?? [];


  const menuItems: MenuProps["items"] = [
    {
      label:  <Dropdown
              menu={{ items }}
              arrow={{ pointAtCenter: true }}
              placement={"bottom"}
              overlayStyle={{ width: "150px" }}
            >
              <a onClick={e => e.preventDefault()}>
                <Space>
                  {dropdownTitle}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>,
      key: `${dropdownTitle}`,
    },
    { label: <NavLink to={"/resources"}>Resources</NavLink>, key: "Orders" },
    { label: <NavLink to={"/about"}>About</NavLink>, key: "Items" },
  ]

  const mobileMenuItems: MenuProps["items"] = [
    {
      label: (
        <Flex vertical>
          <small className={"small-sub"}>{pathname === '/' ? '01 - Active now' : '01'}</small>
          <NavLink to={"/"}>Home</NavLink>
        </Flex>
      ),
      key: "Home",
    },
    {
      label: (
        <Flex vertical className={"link-container"}>
          <small className={"small-sub"}>02{activeNow ? ' - Active now' : ''}</small>
          {dropdownTitle}
        </Flex>
      ),
      key: `${dropdownTitle}`,
      children: items,
    },
    ...mainLinks
  ]


  return (
    <React.Fragment>
      {!isTabletMD ?
        <Navigation mode={"horizontal"} activeKey={pathname} defaultActiveFirst items={menuItems} />
        :
        <React.Fragment>
          <MenuOutlined style={pathname === '/' ? {color: 'white', fontSize: '25px'} : {fontSize: '25px'}} onClick={showDrawer} />
          <Drawer
            maskClosable={false}
            size={"large"}
            style={{ background: `linear-gradient(137.89deg, #051F2E 28.68%, #023F4D 86.43%)` }}
            className={"drawer-container"}
            styles={{ header: { border: "none" }, footer: { border: "none" } }}
            closeIcon={false}
            width={"100vw"}
            placement={"right"}
            onClose={onClose}
            open={open}
          >
            <Flex className={"drawer-header"}>
              <Logo />
              <CloseOutlined onClick={onClose} />
            </Flex>
            <Navigation className={'burger-menu'} mode={"inline"} activeKey={pathname} defaultActiveFirst items={mobileMenuItems} onSelect={onClose} />
          </Drawer>
        </React.Fragment>}
    </React.Fragment>
  )
}
export { HeaderMenu };
